import React from 'react'

import {
  Container,
  Col,
  Row,
} from 'reactstrap'

import SEO from '../../../components/seo'

class privacyPolicy extends React.Component {

  render() {

  const seoData = {
      title: 'Politique de vie privée',
      description: 'Politique de vie privée',
      coverImage: '',
      path: this.props.location.pathname,
      schemaOrg: ''
    }

    return (
      <div>
        <SEO seoData={seoData} /> 
        <Container>
          <Row className="justify-content-center">
            <Col lg={12} md={12} xs={12}>
              <h1>Politique de vie Privée</h1>
              <ol>
              <li><strong>Engagement d’Irriglobe au sujet de la protection de la vie privée</strong></li>
              <p>La protection de votre vie privée est importante pour Irriglobe inc. Dans le cadre de notre engagement à cet égard, nous avons mis en oeuvre la présente politique, qui explique nos pratiques dans ce domaine, y compris celles qui s’appliquent à nos sites Web. Il se peut que nous modifiions la présente politique à l’occasion afin de mieux répondre à vos besoins. Un avis de tout changement sera affiché sur nos sites Web et une copie de toutes les modifications sera conservée dans nos dossiers à nos bureaux. Notre politique relative à la protection de la vie privée respecte toutes les exigences des lois applicables en la matière, y compris la loi du Canada intitulée Loi sur la protection des renseignements personnels et les documents électroniques (LPRPDE).</p>
              <p>La présente politique sur la protection de la vie privée régit la façon dont nous traitons vos renseignements personnels. Si vous avez des questions ou des préoccupations au sujet de la protection de votre vie privée, veuillez communiquer avec nous en utilisant les renseignements pertinents qui figurent à la fin de la présente politique.</p>
              <p>Chaque fois que vous nous fournissez des renseignements personnels, que ce soit par écrit, par téléphone ou en ligne, nous vous renverrons à la présente politique sur la protection de la vie privée. Si vous n’êtes pas d’accord avec notre politique sur la protection de la vie privée, veuillez communiquer avec nous à l’adresse indiquée à la fin du présent document et nous nous ferons un plaisir d’examiner vos préoccupations.</p>
              <li><strong>Qu’est-ce qu’un « renseignement personnel » ?</strong></li>
              <p>Un « renseignement personnel » est un renseignement qui vous concerne vous comme personne. Il peut s’agir, par exemple, de votre nom, de vos adresses postale et électronique, de votre âge, de votre date de naissance, de votre numéro de téléphone, d’une photographie de vous ou d’un enregistrement de votre voix. Vos goûts personnels, comme votre couleur préférée ou votre saveur de crème glacée préférée, pourraient également être considérés comme des renseignements personnels, s’ils sont reliés à d’autres renseignements qui permettent de vous identifier. La présente politique décrit les types de renseignements personnels que nous recueillons, la façon dont nous procédons à cette fin, la raison pour laquelle nous le faisons et l’usage que nous leur réservons.</p>
              <p>Les « renseignements non identifiables » sont des renseignements qui ne peuvent être associés ou reliés à une personne précise. Il peut s’agir de renseignements cumulatifs anonymes, de renseignements qui portent sur vos visites à nos sites Web mais qui ne sont pas associés à vous personnellement ou encore de renseignements relatifs à votre système informatique et à votre logiciel explorateur du Web. Votre ordinateur et ses systèmes d’exploitation, ses navigateurs et sa mémoire renferment de vastes quantités de données concernant l’utilisation que vous en faites. Des tierces parties pourraient obtenir ces renseignements personnels si vous ne prenez pas de mesures pour protéger votre système. Nous avons pour politique de ne pas associer ces renseignements à nos clients, même si nous vérifions certains types de renseignements techniques pour nous assurer que nos sites Web sont optimisés de façon à répondre aux besoins de notre clientèle.</p>
              <p>Lorsque vous interagissez avec nos sites Web, nous pouvons utiliser une caractéristique du navigateur appelée « cookie » (témoin) afin de vérifier les renseignements non identifiables qui concernent nos visiteurs et mesurer les tendances du trafic de ceux-ci. Nous le faisons afin d’offrir des sites Web de la meilleure qualité qui soit. Un témoin est un petit fichier informatique. Lorsque vous visitez nos sites Web pour la première fois, notre gestionnaire de site aura peut-être mis en oeuvre un programme qui crée un petit fichier informatique emmagasiné sur le disque dur de votre ordinateur. Nous veillons à ce que les renseignements minimaux nécessaires soient emmagasinés dans le témoin uniquement afin que nous puissions reconnaître les personnes qui visitent à nouveau notre site. Le témoin ne renferme aucun renseignement personnel et nous ne joindrons pas aux renseignements figurant dans le témoin des renseignements personnels qui nous permettraient de connaître votre identité. Lors de vos visites subséquentes de notre site Web, notre serveur communique avec votre ordinateur, lit le témoin, vous reconnaît et confirme que vous êtes un visiteur habituel. Notre serveur utilise ces renseignements pour vous offrir un « raccourci » qui fait de votre visite une expérience à la fois facile, rapide, efficace et intéressante.</p>
              <p>De plus, des tiers, tels que nos partenaires publicitaires, peuvent utiliser des témoins leur permettant de vérifier les renseignements non identifiables qui vous concernent. Ces renseignements collectés peuvent notamment permettre d’identifier vos comportements, préférences et intérêts. Cette utilisation est sujette à la politique de la vie privée de ces tiers.</p>
              <p>La plupart des logiciels de navigateur vous permettent de supprimer les témoins. Vous devriez consulter votre fournisseur de services Internet pour obtenir des renseignements sur la façon de procéder à cette fin. Si vous supprimez le témoin, il se peut que nous ne soyons pas en mesure de personnaliser votre expérience sur nos sites Web ou que le processus soit ralenti.</p>
              <li><strong>Collecte et utilisation de renseignements personnels</strong></li>
              <p>Nous recueillons des renseignements personnels dans les circonstances suivantes :</p>
              <ul>
              <li>lorsque vous adhérez à un club ou devenez un membre par l’intermédiaire de l’un de nos sites Web, par la poste ou par messagerie texte SMS;</li>
              <li>lorsque vous vous inscrivez à un concours ou à une promotion par l’intermédiaire de l’un de nos sites Web, par la poste ou par messagerie texte SMS;</li>
              <li>lorsque vous vous abonnez à nos bulletins diffusés par courrier électronique;</li>
              <li>lorsque vous répondez à nos questionnaires en ligne;</li>
              <li>lorsque vous participez à des activités interactives en ligne (y compris des jeux, des bavardoirs ou des babillards);</li>
              <li>lorsque vous faites des achats en ligne sur nos sites Web;</li>
              <li>ou lorsque vous communiquez avec nous pour formuler des demandes ou commenter nos services.</li>
              </ul>
              <p>Nous exerçons une prudence toute spéciale lorsque nous recueillons des renseignements personnels de la part d’enfants âgés de moins de 13 ans. Nous ne demandons pas aux enfants de fournir des renseignements personnels afin de leur permettre d’accéder à tout site Web d’Irriglobe inc. et d’en visualiser le contenu. Toutefois, certains renseignements personnels pourraient devoir être fournis avant qu’un enfant ne puisse participer à un de nos concours ou à une de nos promotions. Dans de tels cas, nous pouvons recueillir le prénom et le nom de famille (ou le nom d’utilisateur) de l’enfant, ses adresses électronique et postale, sa date de naissance et son sexe, de même que le nom, les adresses électronique et postale et le numéro de téléphone des parents ou du tuteur de l’enfant.</p>
              <p>Nous ne recueillons que les renseignements personnels d’enfants âgés de moins de 13 ans qu’il est raisonnable de demander afin de vérifier l’âge de l’enfant, d’administrer la participation de l’enfant au concours ou à la promotion et, si l’enfant gagne un prix, pour communiquer avec les parents ou le tuteur de l’enfant. Nous pouvons également fournir ces renseignements personnels à l’entité offrant le prix afin d’assurer sa remise au gagnant. Nous n’utiliserons, conserverons ou dévoilerons ces renseignements personnels pour aucune autre raison.</p>
              <p>Lorsque nous recueillons des renseignements personnels, nous recommandons habituellement à l’enfant d’imprimer une copie de la présente politique et de la remettre à ses parents ou à son tuteur. Cette personne peut ensuite communiquer avec nous en tout temps afin d’obtenir des renseignements plus détaillés au sujet de nos pratiques en matière de protection de la vie privée ou de nous demander de retirer les renseignements personnels de l’enfant de nos dossiers.</p>
              <p>Irriglobe inc. s’efforce de prendre toutes les mesures nécessaires pour veiller à ce que l’expérience que nous offrons aux jeunes sur nos sites Web soit amusante et sans danger et nous enjoignons vivement les parents et les tuteurs à jouer un rôle actif et à surveiller les endroits que leurs enfants explorent sur Internet.</p>
              <p>Ce site Web peut comporter des liens vers des sites Web de tiers. Ces derniers se sont dotés d’une politique de protection de la vie privée distincte et indépendante. Les renseignements personnels que vous partagez avec ces tiers par l’intermédiaire de leurs sites Web seront administrés aux termes de leur propre politique en matière de protection de la vie privée. Irriglobe inc. ne peut exercer de contrôle sur de telles politiques et ne saurait donc assumer de responsabilité ou d’obligation quant à la façon dont des tiers peuvent recueillir, utiliser, divulguer ou traiter vos renseignements personnels.</p>
              <p>La section qui suit décrit plus en détail la façon dont nous recueillons et utilisons les renseignements personnels.</p>
              <li><strong>Clubs ou adhésions</strong></li>
              <p>Bon nombre de nos sites Web vous offrent la possibilité de devenir membre et, de ce fait, de participer régulièrement à des jeux, babillards, concours et autres activités qui y sont proposés. Nous recueillons des renseignements personnels au moment de votre adhésion conformément aux principes suivants:</p>
              <ul>
              <li>nous recueillons uniquement les renseignements personnels nécessaires aux fins des activités proposées;</li>
              <li>et l’accès à nos activités n’est en aucun cas assujetti à la condition que vous fournissiez davantage de renseignements personnels que ceux qui sont nécessaires.</li>
              </ul>
              <li><strong>Concours</strong></li>
              <p>Lorsque vous vous inscrivez à un concours, il se peut que vous soyez tenu de fournir les renseignements personnels suivants : votre nom, votre adresse2 et votre province de résidence, votre adresse électronique, votre numéro de téléphone2 et, lorsqu’une exigence relative à l’âge s’applique, votre groupe d’âge. Nous recueillons ces renseignements personnels dans le seul but de pouvoir identifier les gagnants des concours, de communiquer avec eux et d’assurer la remise des prix. Les renseignements personnels provenant des personnes autres que les gagnants sont détruits conformément aux exigences juridiques applicables. Les personnes ayant gagné un prix (ou les parents ou leur tuteur, lorsque le gagnant est mineur) pourrons recevoir, pour signature, un affidavit d’admissibilité et de renonciation avant que le prix ne soit attribué. Lorsque la renonciation n’est pas retournée dans le délai prescrit, le gagnant abandonne le prix et un autre gagnant peut être choisi.</p>
              <li><strong>Abonnements aux bulletins</strong></li>
              <p>Lorsque vous vous abonnez à nos bulletins en ligne, vous devez fournir les renseignements personnels suivants obligatoires, afin que nous puissions vous envoyer le bulletin en question par voie électronique : votre prénom et votre adresse électronique ainsi que votre province de résidence (dans le dernier cas, à des fins statistiques seulement). Les abonnés (et leurs parents ou tuteurs, le cas échéant) peuvent annuler l’abonnement à un bulletin en tout temps en suivant les directives concernant la résiliation de l’abonnement qui sont énoncées à la fin du bulletin ou en utilisant les renseignements figurant à la fin de la présente politique. Nous supprimons les renseignements personnels de notre base de données dès que nous recevons une demande d’annulation d’abonnement.</p>
              <li><strong>Questionnaires en ligne</strong></li>
              <p>Il se peut que nous vous demandions à l’occasion de nous faire part de vos commentaires en ligne au sujet d’une activité, d’un produit ou d’un service que nous offrons ou au sujet du contenu de nos sites Web. En pareil cas, nous demandons votre nom et votre adresse électronique lorsque vous nous fournissez vos commentaires afin que nous puissions vous répondre, mais vous avez toujours la possibilité de nous faire part de vos commentaires sans donner ces renseignements.</p>
              <li><strong>Autres activités en ligne</strong></li>
              <p>Lorsque vous visitez nos sites Web et participez à d’autres activités en ligne, nous pouvons recueillir des renseignements comme votre nom, votre surnom, votre groupe d’âge, vos préférences, vos opinions, etc. Que vous soyez tenu ou non d’adhérer à un club ou de devenir membre du site afin de participer à ces activités, nous respectons en tout temps les principes énoncés à l’alinéa a) qui précède en ce qui concerne l’adhésion.</p>
              <li><strong>Achats en ligne</strong></li>
              <p>Afin de pouvoir faire des achats en ligne sur nos sites Web vous devez être âgé au minimum de 18 ans. Nos sites Web d’achats en ligne recueillent uniquement les renseignements essentiels aux fins des commandes, y compris votre nom et votre adresse d’expédition, votre numéro de téléphone et votre adresse électronique ainsi que votre numéro de carte de crédit, et ce, dans l’unique but de traiter votre commande et de livrer les produits ou d’exécuter les services que vous achetez en ligne. Nous ne communiquerons pas ces renseignements à l’extérieur d’Astral Media, sauf à nos mandataires approuvés qui nous aident à traiter votre commande. Tous nos mandataires approuvés sont tenus d’assurer un niveau de protection de la vie privée comparable à celui qui est exigé dans la présente politique et aucun d’eux n’est autorisé à utiliser vos renseignements personnels à une autre fin, notamment pour faire affaire avec vous ou faire de la publicité de leurs produits auprès de vous.</p>
              <li><strong>Contact avec le service à la clientèle</strong></li>
              <p>Si vous nous téléphonez ou que vous nous écrivez par la poste ou par courrier électronique afin de commenter l’un de nos services ou encore nos sites Web, nous conserverons les renseignements dans nos dossiers pendant un certain temps. En pareil cas, la divulgation de votre nom et de vos renseignements personnels est volontaire.</p>
              <li><strong>Divulgation des renseignements personnels</strong></li>
              <p>Nous ne divulguerons pas vos renseignements personnels, à moins que nous ne soyons tenus de le faire en vertu de la loi. Il se peut que la loi nous oblige ou nous autorise à fournir des renseignements personnels, en réponse, par exemple, à une requête en bonne et due forme, à un mandat de perquisition ou à toute autre demande de renseignements ou ordonnance juridique valide. Nous pourrions en outre communiquer des renseignements à un organisme d’enquête en cas de bris de contrat ou de violation d’une loi ou si nous y sommes par ailleurs tenus en vertu de la loi. Nous utilisons vos renseignements personnels à des fins internes uniquement et nous nous abstenons de les vendre, de les louer ou d’en faire le commerce. Nous pouvons transférer des renseignements à des tiers nécessitant de tels renseignements personnels afin de fournir un service pour le compte d’Irriglobe. Tout tel fournisseur de services est tenu par contrat de respecter le caractère confidentiel de tels renseignements personnels transférés et il lui est interdit d’utiliser les renseignements personnels pour quelque autre raison que ce soit. Vous pouvez retirer en tout temps le consentement que vous avez donné quant à notre utilisation de vos renseignements personnels en suivant les directives figurant à la fin de la présente politique. Nous obtiendrons votre consentement avant d’utiliser vos renseignements personnels à des fins autres que celles qui sont énoncées ci-dessus. Nous détruisons vos renseignements personnels dès qu’ils ne sont plus nécessaires aux fins susmentionnées.</p>
              <li><strong>Sécurité et mesures de protection</strong></li>
              <p>Nous savons à quel point la protection de la vie privée est importante à vos yeux. C’est pourquoi nous protégeons vos renseignements personnels. Nos politiques en matière de sécurité régissent la protection des renseignements contenus dans nos systèmes d’information, la façon dont nous protégeons nos sites Web des attaques malveillantes et la façon dont nous éliminons les renseignements qui ne sont plus nécessaires. Nous choisissons et formons nos employés avec soin afin de nous assurer qu’ils accorderont en tout temps la plus haute importance à la protection de votre vie privée et nous permettons l’accès à vos renseignements personnels uniquement aux personnes qui doivent les connaître.</p>
              <p>Nous déployons nos meilleurs efforts pour conserver nos serveurs dans un environnement sûr et protéger notre infrastructure informatique des emplois abusifs. La sécurité sur Internet est un problème qui ne cesse de prendre de l’ampleur et nous retenons les services de spécialistes qui nous tiennent au courant des plus récentes tendances. Si vous recevez une communication électronique qui semble provenir d’un membre d’Irriglobe et que vous avez des questions ou des préoccupations à ce sujet, veuillez communiquer avec nous. Le pourriel, l’usage abusif et le piratage des noms de domaine et des adresses électroniques représentent un problème croissant et nous voulons donc être informés des incidents qui se produisent afin de pouvoir mener une enquête à ce sujet et vous offrir le meilleur service à la clientèle possible.</p>
              <li>
              <strong>Comment nous joindre pour nous faire part de vos préoccupations, mettre à jour vos renseignements personnels, refuser ou retirer votre consentement ou formuler une plainte</strong></li>
              <p>Nous avons tenté d’anticiper toutes vos attentes et d’y répondre en ce qui concerne le traitement de vos renseignements personnels et la protection de votre vie privée dans le cadre de votre expérience en ligne avec nous. Si vous avez des questions ou des préoccupations, veuillez communiquer avec nous à l’adresse indiquée ci-dessous. C’est l’adresse à utiliser pour nous joindre dans les cas suivants :</p>
              <ul>
              <li>lorsque vous avez des questions ou des préoccupations au sujet de la façon dont nous traitons vos renseignements personnels;</li>
              <li>lorsque vous souhaitez mettre à jour ou consulter vos renseignements personnels;</li>
              <li>lorsque vous souhaitez retirer votre consentement à la collecte et à l’utilisation de vos renseignements personnels ou des renseignements personnels au sujet de votre enfant, si vous êtes un parent ou un tuteur;</li>
              <li>
              ou lorsque vous désirez mettre fin à un abonnement à un bulletin en ligne.</li>
              </ul>
              </ol>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default privacyPolicy
